import * as React from 'react';

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

interface Errors {
    email?: string,
    name?: string,
    message?: string
}

class Contact extends React.Component {
    state = {
        name: '',
        email: '',
        message: '',
        errors: {
            name: '',
            email: '',
            message: ''
        },
        submitted: false
    };


    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = async e => {
        e.preventDefault();
        const { email, name, message } = this.state
        const errors: Errors = {};

        if (name === '') {
            errors.name = 'Please enter your name'
        }

        if (email === '') {
            errors.email = 'Please enter a valid E-mail address'
        }

        if (message === '') {
            errors.message = 'Please enter a message'
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors
            })
            return;
        }

        try {
            await fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": "contact", ...this.state })
            })

            this.setState({
                name: '',
                email: '',
                message: '',
                errors: {},
                submitted: true
            })

        } catch (error) {
            alert(error)
        }

    };

    render() {
        const { email, name, message, errors } = this.state
        return (
            <div style={{ marginTop: `15rem`, marginBottom: `10rem` }}>
                <h2>contact</h2>
                <div style={{ width: `85%`, marginTop: `5rem` }}>
                    <form name="contact" onSubmit={this.onSubmit}>
                        <div className="float-container">
                            <label>Name*</label>
                            <input type="text" name="name" value={name} onChange={this.onChange} />
                        </div>
                        {errors.name && <div className="error mb-2">{errors.name}</div>}
                        <div className="float-container">
                            <label>E-mail*</label>
                            <input type="email" name="email" value={email} onChange={this.onChange} />
                        </div>
                        {errors.email && <div className="error mb-2">{errors.email}</div>}
                        <div className="float-container">
                            <label>Message*</label>
                            <textarea name="message" value={message} onChange={this.onChange}></textarea>
                        </div>
                        {errors.message && <div className="error mb-2">{errors.message}</div>}
                        <div style={{ float: `right` }}>
                            {this.state.submitted && <p className="d-inline mr-3">Thank you for your message - I'll get back to you as soon as possible!</p>}
                            <button type="submit">Send</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default Contact;