import * as React from 'react';
import Name from '../../images/name.svg';
import NavItems from './navItems';

interface IProps {
    scrollTop: Number
}

class Menu extends React.Component<IProps>{

    handleClick = e => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    
    render(){
        let opacity = 0;
        if (this.props.scrollTop > 300) {
            opacity = 1;
        }
        return (
            <div className="container-fluid" style={{ position: `fixed`, top: `0px` }}>
                <div className="row">
                    <div className="col-3" style={{ height: `1000px` }}>
                        <Name 
                            style={{ 
                                width: `80px`, 
                                top: `-30px`, 
                                left: `20%`, 
                                position: `absolute`, 
                                transition: `opacity 300ms ease-in-out 0s`, 
                                opacity, 
                                cursor: `pointer` }} 
                            onClick={this.handleClick}/>
                        <div style={{ marginTop: `43vh`, transition: `opacity 300ms ease-in-out 0s`, opacity }}>
                            <NavItems scrollTop={this.props.scrollTop} />
                        </div>
                    </div>
                </div>
            </div>
    
        )
    }

}

export default Menu;