import * as React from 'react';
import LeonPhoto from '../images/img-components/leonPhoto';

interface IProps {
    mobile: Boolean
}

class About extends React.Component<IProps> {
    handleClick = e => {
        window.scrollTo({ top: 3200, behavior: 'smooth' });
    }

    render(){
        return (
            <React.Fragment>
                <h2>about</h2>
                <div className="row" style={{ width: `85%` }}>
                    <div style={{marginTop: `5rem`}} className="col-12 col-md-6">
                        <h3>Hey, my name is Leon</h3>
                        <p className="mt-4">I design and develop fullstack web applications and websites.</p>
                        <p style={{ marginTop: `-10px` }}>Focus of my work is to conceptualize and create projects <br />
                            that are aligned with human needs and desires.</p>
                        <p style={{ marginTop: `45px` }}>You have an interesting Project that you need help with? <br />
                            write me an <u style={{cursor: `pointer`}} onClick={this.handleClick}>email</u></p>
                    </div>
                    <div className="col-12 col-md-6">
                        <div style={{marginTop: `1rem`, maxWidth: `200px`, marginLeft: !this.props.mobile ? `40%` : `0%`}} >
                            <LeonPhoto />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default About;