import * as React from 'react';
import PolygonSVG from '../../images/polygon.svg';

interface IProps {
    scrollTop: Number
    innerWidth: Number
}

const Polygon = (props) => {

    const scrollLimit = 400;
    let translate = props.scrollTop * props.innerWidth / 1000
    let position = `absolute`
    let top = `auto`
    let rotate = props.scrollTop
    if (props.scrollTop >= scrollLimit) {
        translate = scrollLimit * props.innerWidth / 1000
        rotate = scrollLimit
        position = `fixed`
        top = `-8vh`
    }
    if (props.innerWidth < 960){
        position = `absolute`
        top = `auto`
    }

    return (
        <PolygonSVG style={{
            position,
            top,
            marginLeft: `43.2%`,
            transform: `translate(-${translate}px, ${translate / 4}px) rotate(${rotate * 0.9}deg) scale(${1 - rotate / 600})`
        }} />
    )
}

export default Polygon;