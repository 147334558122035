import * as React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css'

import SEO from "../components/seo";
import Landing from "./landing/index";
import Menu from "./menu/index";
import Polygon from "./landing/polygon";
import About from "./about";
import Works from "./works/index";
import Contact from "./contact";
import Github from '../images/github.svg';
import Twitter from '../images/twitter-icon.svg';
import LinkedIn from '../images/linkedin-icon.svg';

interface IProps { }

interface IState {
  scrollTop: Number
  innerWidth: Number
}

class IndexPage extends React.Component<IProps, IState>{

  state = {
    scrollTop: 0,
    innerWidth: 0
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (event) => {
    const { scrollTop } = event.srcElement.scrollingElement
    this.setState({ scrollTop })
  }

  updateWindowDimensions = () => {
    this.setState({ innerWidth: window.innerWidth })
  }

  render() {
    const { scrollTop, innerWidth } = this.state

    let body;
    const mobile = innerWidth < 720

    if (innerWidth > 960) {
      body = <div className="row">
        <div className="col-2" style={{ height: `1000px` }}>
          <Menu scrollTop={scrollTop} />
        </div>
        <div className="col-1" />
        <div className="col-9">
          <div style={{ marginLeft: `5%` }}>
            <About mobile={mobile} />
            <hr style={{ borderColor: '#000', marginTop: `5rem` }} />
            <Works mobile={mobile} />
            <hr style={{ borderColor: '#000', marginTop: `5rem` }} />
            <Contact />
          </div>
        </div>
      </div>
    } else {
      body = <div style={{ marginLeft: `15%` }}>
        <About mobile={mobile} />
        <hr style={{ borderColor: '#000', marginTop: `5rem` }} />
        <Works mobile={mobile} />
        <hr style={{ borderColor: '#000', marginTop: `5rem` }} />
        <Contact />
        <div className="text-center" style={{ marginLeft: `-20%` }} >
          <a href="https://github.com/leonvdb" target="_blank"><Github className="mr-4 social-icon" style={{ width: `30px`, height: `30px` }} /></a>
          <a href="https://www.linkedin.com/in/leon-vdb/" target="_blank"><LinkedIn className="mr-4 social-icon" /></a>
          <a href="https://twitter.com/VdbLeon" target="_blank"><Twitter className="social-icon" /></a>
          <p className="mt-2">leon.vondemberge@gmail.com</p>
          <p style={{ marginTop: `-15px` }}>+972586000965</p>
        </div>
      </div>
    }
    return (
      <React.Fragment>
        <SEO title="Leon von dem Berge" keywords={[`Leonvdb`, `HumaneTech`, `react`, `Freelance`, `website`, `Design`, `UX`]} />
        <Landing scrollTop={scrollTop} />
        <Polygon scrollTop={scrollTop} innerWidth={innerWidth} />
        <div className="container-fluid" style={{ marginTop: `37rem` }} >
          {body}
        </div>
      </React.Fragment>
    )
  }


}

export default IndexPage
