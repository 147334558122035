import * as React from 'react';
import classnames from 'classnames';
import Name from '../../images/name.svg';
import Arrow from '../../images/arrow.svg'

interface IProps {
    scrollTop: Number
}


const Landing = (props: IProps) => {

    let opacity = 1;
    if (props.scrollTop > 100) {
        opacity = 0;
    }

    return (
        <React.Fragment>
            <div className="mx-auto" style={{ maxWidth: `261px`, marginTop: `20vh` }}>
                <Name style={{ transition: `opacity 300ms ease-in-out 0s`, opacity }} />
            </div>
            <div className="toggle-fade">
                <div className={classnames("text-center scroll-label", { "fade-out": props.scrollTop > 100 })}>
                    scroll
            <Arrow className="ml-1 mb-1" />
                </div>
            </div>
        </React.Fragment>)

}


export default Landing