import * as React from 'react';
import Carousel from './carousel';
import Github from '../../images/github.svg';
import DavidHome from '../../images/img-components/davidHome';
import DavidBath from '../../images/img-components/davidBath';
import DavidBomb from '../../images/img-components/davidBomb';
import DavidAbout from '../../images/img-components/davidAbout';
import ConsensusOverview from '../../images/img-components/consensusOverview'
import ConsensusResultsList from '../../images/img-components/consensusResultsList'
import ConsensusResultsTable from '../../images/img-components/consensusResultsTable';
import classnames from 'classnames';


const davidImages = [
    { src: <DavidHome />, altText: "DavidHome" },
    { src: <DavidBath />, altText: "DavidBath" },
    { src: <DavidBomb />, altText: "DavidBomb" },
    { src: <DavidAbout />, altText: "DavidAbout" },
]

const consensusImages = [
    { src: <ConsensusResultsTable />, altText: "ConsensusAuth" },
    { src: <ConsensusOverview />, altText: "ConsensusOverview" },
    { src: <ConsensusResultsList />, altText: "ConsensusResultsList" },
]

const Works = (props) => {
    return (
        <div style={{ marginTop: `15rem` }}>
            <h2>works</h2>
            <div style={{ width: `85%`, marginTop: `5rem` }}>
                <div className="work-title mb-2">David Simulator 2018</div>
                <Carousel images={davidImages} />
                <div className="row">
                    <div className="col-12 col-md-6">
                        <p className="mt-3 work-description">David Simulator is a web based visual text adventure, <br />
                            portraying the life of my good friend David.
                        <br />
                            <i>(Currently only available in German)</i>
                        </p>
                        <p className="mt-3 work-description"><b>I worked with:</b> Python, Flask, PostgreSQL, PyTest, SQLAlchemy</p>
                    </div>
                    <div className={classnames("col-12 col-md-6 mt-3", { "text-right": !props.mobile })}>
                        <a className="d-block" target="_blank" href="http://www.davidsimulator.com/">www.davidsimulator.com</a>
                        <div className="mt-1">
                            <a target="_blank" href="https://github.com/leonvdb/david_simulator">
                                <Github style={{ marginTop: `-6px`, marginRight: `5px` }} />
                                <span>
                                    /david_simulator
                                </span>
                            </a>
                        </div>
                    </div>

                </div>

            </div>
            <div style={{ width: `85%`, marginTop: `10rem` }}>
                <div className="work-title mb-2">D'accord</div>
                <Carousel images={consensusImages} />
                <div className="row">
                    <div className="col-12 col-md-6">
                        <p className="mt-3 work-description">A democratic decisionsmaking tool based on the principles
                            of "systemic consensus" to find sustainable and conflict free solutions.</p>
                        <p className="mt-3 work-description"><b>I worked with:</b> TypeScript, React.js, Redux, GraphQL, Apollo, MongoDB, Express, Node.js</p>
                    </div>
                    <div className={classnames("col-12 col-md-6 mt-3", { "text-right": !props.mobile })}>
                        <a className="d-block" target="_blank" href="https://www.daccordapp.com/">www.daccordapp.com</a>
                        <div className="mt-1">
                            <a target="_blank" href="https://github.com/leonvdb/daccord">
                                <Github style={{ marginTop: `-6px`, marginRight: `5px` }} />
                                <span>
                                    /daccord
                                </span>
                            </a>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Works;